




















































































import Vue from 'vue'
import Component from 'vue-class-component'
import { AccountPresentation, IAccountPresentation } from '@/presentation/user/AccountPresentation'
import ManageTable from '@/components/shared/ManageTable.vue'
import SimpleDataTable from '@/components/shared/SimpleDataTable.vue'
import BasicDialog from '@/components/commons/BasicDialog.vue'
import FormItems from '@/components/shared/FormItems.vue'
import FormItemsOption from '@/models/forms/FormItemsOption'
import { regexToPatternString } from '@/data/Regex'
import { Prop } from 'vue-property-decorator'
import { CorpMemberPermission } from '@/gateway/affiliate/CorpMembers'
import { CountryPhoneCodes } from '@/data/CountryPhoneCodes'
import FormRule from '@/models/forms/FormRule'

@Component({
  components: { FormItems, BasicDialog, SimpleDataTable, ManageTable }
})
export default class AccountManagement extends Vue {
  @Prop({
    type: Boolean,
    required: false,
    default: false
  })
  isSignUp!: Boolean
  isReady = false
  presentation: IAccountPresentation = new AccountPresentation()
  addMemberModal = false

  phoneCodes: Array<{ label: string; value: number; key: string }> = CountryPhoneCodes.map(country => {
    return { label: `${country.phone_code} (${country.name})`, value: country.phone_code, key: country.name }
  })
  get memberWrapperClass() {
    return this.isSignUp ? 'corp-member-wrapper sign-up' : 'corp-member-wrapper'
  }

  get label() {
    return {
      title: this.isSignUp ? '' : `${this.$t('commons.sentbiz')} ${this.$t('menu.manage_members')}`,
      subtitle: this.isSignUp ? '' : this.$t('paragraphs.manage_account.explain'),
      manager: `${this.$t('commons.owner')} (${this.$t('commons.required_type')})`,
      // member: this.$t('commons.member'),
      addMember: this.isSignUp ? this.$t('commons.do_save') : this.$t('commons.send_invitation_email')
    }
  }

  get hideColumnProps() {
    return ['corp_id', 'id']
  }

  get hideColumnLabels() {
    return ['edit']
  }

  get addMemberFormOptions(): Array<FormItemsOption> {
    return [
      
      {
        prop: 'phone_code',
        type: 'select',
        label: this.$t('sheet.field.phone_code') as string,
        class: 'phone-code-select',
        filterable: true,
        options: this.phoneCodes
      },
      {
        prop: 'phone_number',
        label: this.$t('sheet.field.phone_number') as string,
        type: 'text'
      },
      {
        prop: 'email',
        label: this.$t('sheet.field.email') as string,
        type: 'text'
      },
    ]
  }

  get addMemberFormRules(): Dictionary<Array<FormRule>> {
    return {
      email: [
        {
          pattern: regexToPatternString('email'),
          message: this.$t('commons.check_write_format') as string,
          trigger: 'blur'
        },
        {
          required: true,
          message: ' '
        }
      ],
      phone_number: [
        {
          pattern: regexToPatternString('number'),
          message: this.$t('commons.check_write_format') as string,
          trigger: 'blur'
        },
        {
          required: true,
          message: ' '
        }
      ]
    }
  }

  openAddMemberModal() {
    this.presentation.resetAddMemberField()
    this.addMemberModal = true
  }

  async onSubmitAddAdmin() {
    const isSuccess = await this.presentation.addMember(this.$refs.addAdminForm, CorpMemberPermission.ADMIN)
    if (!isSuccess) return
    await this.presentation.initialize()
  }

  async onSubmitAddMember() {
    const isSuccess = await this.presentation.addMember(this.$refs.addMemberForm, CorpMemberPermission.MEMBER)
    if (!isSuccess) return
    this.addMemberModal = false
    await this.presentation.initialize()
  }

  async created() {
    await this.presentation.initialize()
    this.isReady = true
  }
}
